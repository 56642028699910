@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

*{font-family: 'Montserrat', sans-serif;}
h1, h2, h3, h4 ,h5 ,h6{font-family: 'Cormorant Garamond', Serif !important;}

.auth-bg{
    background-image: url('../images/auth-bg.jpg');
    background-size: cover;
    min-height: calc(100vh - 64px);
    background-position: center center;
}
.sidebar-bg{
    background-image: url('../images/background-texture.png');
    background-position: center center;
    background-size: cover;
}

.v-text-field--outlined, .v-text-field--solo, .v-btn{
    border-radius: 0;
}

.registration-stepper .v-stepper__step--editable .mdi-pencil:before{
    content: "\F12C" !important;
}

.registration-stepper .v-stepper__step__step{
    height: 40px !important;
    min-width: 40px !important;
    width: 40px !important;;
    font-size: 24px !important;;
}

/* Email builder */
.menubar{margin-bottom: 0 !important;}
.editor__content{background: #ffffff !important; padding: 1rem}
/* Hide video */
.menubar > div:nth-child(6){
    display: none !important;
}

.gjs-one-bg{
    background-color: #333333 !important;
}
